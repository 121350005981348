@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}
::-webkit-scrollbar {
  display: none;
}
